/* eslint react/forbid-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import CardElement from '../../../elements/Card';
import CardTitle from '../../../elements/CardTitle';
import CardBody from '../../../elements/CardBody';
import CardContent from '../../../elements/CardContent';
import CardFooter from '../../../elements/CardFooter';
import CardLink from '../CardLink';
import CardImage from '../CardImage';
import CardTags from '../CardTags';

const Card = ({
  imageSizes, title, content, tags, href, linkText,
}) => (
  <CardElement>
    {imageSizes && (
      <Link to={href}>
        <CardImage sizes={imageSizes} />
      </Link>
    )}
    <CardBody>
      {title && <CardTitle>{title}</CardTitle>}
      {tags && <CardTags tags={tags} />}
      {content && <CardContent>{content}</CardContent>}
    </CardBody>
    {href && (
      <CardFooter>
        <CardLink to={href} text={linkText} />
      </CardFooter>
    )}
  </CardElement>
);

Card.defaultProps = {
  imageSizes: null,
  title: null,
  content: null,
  tags: null,
};

Card.propTypes = {
  imageSizes: PropTypes.object,
  title: PropTypes.node,
  content: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  href: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
};

export default Card;

export const query = graphql`
  fragment CardImageSizesFragment on File {
    childImageSharp {
      sizes(maxWidth: 350, maxHeight: 200, cropFocus: CENTER) {
        ...GatsbyImageSharpSizes_noBase64
      }
    }
  }
`;
