import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const SoundIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="0 0 9.5249998 9.5250003" {...props}>
    <g transform="translate(0 -287.47)" stroke="#000">
      <path
        d="m1.0542 290.39h1.0625l1.4592-1.3133c1.1867-1.068 1.1867-1.068 1.1867-0.00966l-1e-7 6.6264c1e-7 0.78193 1e-7 0.78193-1.3163-0.2711l-1.3295-1.0636h-1.0566c-0.53094 0-0.53094 0-0.53094-0.52563v-2.9116c0-0.53153 1e-8 -0.53153 0.52503-0.53153z"
        strokeWidth=".26458px"
      />
      <path
        d="m5.2917 290.65s1.0583 0.26458 1.0583 1.5875-1.0583 1.5875-1.0583 1.5875"
        fill="none"
        strokeWidth=".66146"
      />
      <path
        d="m5.5562 289.06s2.4589 0.52917 2.4589 3.175c-7e-7 2.6458-2.4589 3.175-2.4589 3.175"
        fill="none"
        strokeWidth=".66146"
      />
    </g>
  </SVG>
);

export default SoundIcon;
