import React from 'react';
import SVG from '../SVG';
import styles from './styles.module.css';

const VideoIcon = ({ className = '', ...props }) => (
  <SVG className={`${styles.icon} ${className}`} viewBox="0 0 9.5249998 9.5250003" {...props}>
    <g transform="translate(0 -287.47)">
      <path
        d="m1.0583 289.06h7.4084c0.29316 0 0.52917 0.23601 0.52917 0.52917v5.2917c0 0.29316-0.23601 0.52917-0.52917 0.52917h-7.4084c-0.29316 0-0.52917-0.23601-0.52917-0.52917v-5.2917c0-0.29316 0.23601-0.52917 0.52917-0.52917z"
        strokeWidth=".66146"
      />
      <g fill="#fff">
        <g strokeWidth=".66146">
          <path d="m1.0583 289.59h1.0583v0.79375h-1.0583z" />
          <path d="m2.6458 289.59h1.0583v0.79375h-1.0583z" />
          <path d="m4.2333 289.59h1.0583v0.79375h-1.0583z" />
          <path d="m5.8208 289.59h1.0583v0.79375h-1.0583z" />
          <path d="m7.4083 289.59h1.0583v0.79375h-1.0583z" />
          <path d="m1.0583 294.09h1.0583v0.79375h-1.0583z" />
          <path d="m2.6458 294.09h1.0583v0.79375h-1.0583z" />
          <path d="m4.2333 294.09h1.0583v0.79375h-1.0583z" />
          <path d="m5.8208 294.09h1.0583v0.79375h-1.0583z" />
          <path d="m7.4083 294.09h1.0583v0.79375h-1.0583z" />
        </g>
        <path
          d="m3.7042 290.91v2.6458l2.6458-1.3229-2.6458-1.3229"
          stroke="#000"
          strokeWidth=".25227px"
        />
      </g>
    </g>
  </SVG>
);

export default VideoIcon;
