import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const CardContent = ({ className, ...props }) => (
  <p className={`${styles.content} ${className}`} {...props} />
);

CardContent.defaultProps = {
  className: '',
};

CardContent.propTypes = {
  className: PropTypes.string,
};

export default CardContent;
