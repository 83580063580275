/* eslint react/forbid-prop-types: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styles from './styles.module.css';
import Card from '../../core/Card';
import VideoIcon from '../../../elements/Icons/VideoIcon';
import SoundIcon from '../../../elements/Icons/SoundIcon';

const MediaCard = ({
  media, className, missingImageSizes, ...props
}) => {
  const linkTextFor = {
    audio: 'Listen',
    video: 'Watch video',
  };

  const imageSizes = media.fields && media.fields.thumbnail
    ? media.fields.thumbnail.childImageSharp.sizes
    : missingImageSizes;

  const title = (
    <div className={styles.titleWrapper}>
      <span>{media.title}</span>
      {media.type === 'audio' && <SoundIcon className={styles.icon} />}
      {media.type === 'video' && <VideoIcon className={styles.icon} />}
    </div>
  );

  return (
    <Card
      {...props}
      title={title}
      content={media.short_desc}
      linkText={linkTextFor[media.type]}
      href={`/${media.type}/${media.media_id}`}
      imageSizes={imageSizes}
    />
  );
};

MediaCard.propTypes = {
  media: PropTypes.shape({
    media_id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    short_desc: PropTypes.string,
  }).isRequired,
  missingImageSizes: PropTypes.object.isRequired,
};

export default MediaCard;

export const MediaSummaryFragment = graphql`
  fragment MediaSummaryFragment on MediaJsonConnection {
    media: edges {
      item: node {
        media_id
        type
        title
        short_desc
        fields {
          thumbnail {
            ...CardImageSizesFragment
          }
        }
      }
    }
  }
`;
